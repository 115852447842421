<template>
  <div>
    <BaseTable
      v-model="selectedRecords"
      :headers="mergedDupeSetsTableHeaders"
      :records="mergedDupeSets"
      :per-page="mergedDupeSetsPerPage"
      :total-items="mergedDupeSetsTotalItems"
      :is-loading="isLoading"
      :title="title"
      disable-dragging
      disable-resizing
      tooltip="This list contains merged duplicate sets. Click anywhere within a row to review."
      show-select
      @open="openMergedDuplicateSetsDialog"
      @fetchData="fetchData"
    >
      <template #action>
        <v-btn
          v-show="mergedDupeSets.length > 0"
          color="primary"
          outlined
          large
          class="mr-2"
          :disabled="isLoading"
          @click="exportData"
        >
          <v-icon class="mr-2"> $mdi-download </v-icon>
          EXPORT DATA
        </v-btn>
        <v-btn
          v-show="mergedDupeSets.length > 0"
          color="primary"
          large
          :disabled="isLoading"
          @click="openMergedDuplicateSetsDialog()"
        >
          {{ selectedRecords.length > 0 ? 'REVIEW SELECTED' : 'REVIEW ALL' }}
        </v-btn>
      </template>

      <template #search="{ search, updateSearch }">
        <AdvancedDashboardFilter
          :search="search"
          :update-search="updateSearch"
        />
      </template>
    </BaseTable>

    <template v-if="isNewDupegridEnabled">
      <DupeGridDialog
        v-if="showHistoryDialog"
        is-merged-duplicate-sets
        title="Record Audit Trail"
        type="history"
        :records="dialogMergedDupeSets"
        @close="showHistoryDialog = false"
      />
    </template>

    <template v-else>
      <DupesGridMergeHistoryDialog
        v-model="showHistoryDialog"
        :dupe-sets="dialogMergedDupeSets"
      />
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import BaseTable from './BaseTable'
import DupesGridMergeHistoryDialog from '@/components/dupesgrid/DupesGridMergeHistoryDialog'
import AdvancedDashboardFilter from '../shared/AdvancedDashboardFilter'
import DupeGridDialog from '../dupegrid/DupeGridDialog'

export default {
  components: {
    BaseTable,
    DupesGridMergeHistoryDialog,
    AdvancedDashboardFilter,
    DupeGridDialog,
  },
  props: {
    title: {
      type: String,
      default: 'Merged Duplicate Sets',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exportFilters: {},
      selectedRecords: [],
      showHistoryDialog: false,
      dialogMergedDupeSets: [],
      isNewDupegridEnabled:
        process.env.VUE_APP_NEW_DUPEGRID_ENABLED === 'true' ||
        localStorage.getItem('is_new_dupegrid_enabled') === 'true',
    }
  },
  computed: {
    ...mapState('dashboard', [
      'mergedDupeSets',
      'mergedDupeSetsPerPage',
      'mergedDupeSetsTotalItems',
    ]),
    ...mapGetters('dashboard', ['mergedDupeSetsTableHeaders']),
  },
  methods: {
    async fetchData(event) {
      await this.$emit('load', event)
      this.exportFilters = event
    },
    exportData() {
      this.$emit('export', this.exportFilters)
    },
    openMergedDuplicateSetsDialog(item) {
      if (item) {
        this.dialogMergedDupeSets = [item]
      } else if (this.selectedRecords.length > 0) {
        this.dialogMergedDupeSets = this.selectedRecords
      } else {
        this.dialogMergedDupeSets = this.mergedDupeSets
      }

      this.showHistoryDialog = true
    },
  },
}
</script>
